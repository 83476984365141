import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  Button
} from "reactstrap";

import { deleteSupporter } from "../../../services/account";
import { removeSession } from "../../../services/session";

export default class RevokeSupport extends Component {
  constructor(props) {
    super(props);
    this.goToHome = this.goToHome.bind(this);
  }
  goToHome() {
    this.props.reverseIsVisible();
  }

  delete() {
    deleteSupporter();
    removeSession();
    this.props.reverseIsVisible();
    this.props.sessionUpdated();
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.isVisible}
          centered={true}
          modalClassName="login-wrapper"
          className="login-modal"
        >
          <ModalBody className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span>Are you sure?</span>
            </div>
            <div className="btn-group">
              <Button
                className="btn-danger px-4"
                onClick={e => {
                  this.delete();
                }}
              >
                Yes
              </Button>
              <Button className="px-4" onClick={() => this.goToHome()}>
                No
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
