import React from "react";
import {
  Box,
  useTheme,
  Stack,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  Link,
} from "@mui/material";
import { BeeLogo, SearchIcon } from "../../../img/svg";
import {
  EMAIL,
  footerAddresses,
  footerIcons,
  footerLinks,
} from "../../ImageButton/constant";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { white, black, black_one, black_two, gary, darkGray_one } =
    theme.palette.common;

  return (
    <>
      <Box px={{ xs: 3, md: 4, lg: 8, xl: 20.625 }} py={8} bgcolor={black_one}>
        <Box
          display="grid"
          sx={{
            gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" },
            gap: { md: "25px", lg: "50px", xl: "125px" },
          }}
        >
          <Stack>
            <Stack direction="row" gap={2} alignItems="center" sx={{
              "& svg": {
                marginTop: "-12px"
              }
            }}>
              <BeeLogo />
              <Typography variant="subtitle2" display={{ xs: "none", lg: "block", fontWeight: "bold", color: "#fff" }}>
                {t("logo_text")}
              </Typography>
            </Stack>
            {/* 
            <Stack mt={5}>
              {footerAddresses.map((value) => (
                <Typography variant="body2" color={gary} key={value}>
                  {value}
                </Typography>
              ))}
            </Stack> */}

            <Typography mt={2} variant="body2" color={gary}>
              {EMAIL}
            </Typography>
          </Stack>

          <Stack
            direction={{ xs: "column", md: "row" }}
            gap={{ md: "10px", lg: "40px", xl: "102px" }}
          >
            <Stack mt={{ xs: "60px", md: 0 }}>
              <Typography
                variant="h4"
                lineHeight="30px"
                color={white}
                textTransform="none"
              >
                {t("company")}
              </Typography>
              <Box
                mt={3}
                display="grid"
                sx={{
                  gridTemplateColumns: "repeat(2, 1fr)",
                  rowGap: "12px",
                  columnGap: { lg: "20px", xl: "42px" },
                }}
              >
                {footerLinks.map((value) => {
                  const { text, link } = value;
                  const isTrue = text === "contactUs"
                  return (
                    isTrue ?

                      <a href={`mailto:${link}`}>
                        <Typography
                          variant="subtitle2"
                          lineHeight="26px"
                          minWidth="160px"
                          color={gary}
                          key={text}
                        >
                          {t(text)}
                        </Typography>
                      </a>

                      :

                      <Link href={link} target="_blank" underline="hover">
                        <Typography
                          variant="subtitle2"
                          lineHeight="26px"
                          minWidth="160px"
                          color={gary}
                          key={text}
                        >
                          {t(text)}
                        </Typography>
                      </Link>
                  );
                })}
              </Box>
            </Stack>
            <Stack mt={{ xs: "60px", md: 0 }}>
              <Typography
                variant="h4"
                lineHeight="30px"
                color={white}
                textTransform="none"
              >
                {t("search")}
              </Typography>
              <FormControl variant="standard">
                <TextField
                  sx={{
                    "& .MuiInputBase-root": {
                      marginTop: "24px",
                      backgroundColor: darkGray_one,
                      maxWidth: 255,
                      "& .MuiInputBase-input": {
                        borderRight: "8px",
                        fontSize: "14px",
                        lineHeight: "24px",
                        padding: "9px 0 7px 12px",
                        color: "#fff",
                      },
                    },
                  }}
                  placeholder={t("search")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Box>

      <Stack
        px={{ xs: 1, md: 4, lg: 8, xl: 20.625 }}
        py={3}
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        bgcolor={black}
      >
        <Typography variant="body2" color={gary}>
          © {new Date().getFullYear()} Common Sense for Uniting America. {t("allRightsReserved")}
        </Typography>

        <Stack direction="row" gap={2} mt={{ xs: 3, sm: 0 }}>
          {footerIcons.map((icon, index) => {
            const { element, link } = icon;
            return (
              <Link href={link} target="_blank">
                <Stack
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor={black_two}
                  width="25px"
                  height="25px"
                  borderRadius="50%"
                >
                  {element}
                </Stack>
              </Link>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};

export default Footer;
