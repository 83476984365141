import React from "react";
import Book from "./Initiative";
import Initiative from "./Initiative.pdf";

import "./App.css";

export default function InitiativeBase() {
  return (
    <div>
      <html lang="en">
        <head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          ></meta>
        </head>
        <body>
          <div className="container-fluid" align="center">
            <div className="Example">
              <header align="center">
                <h2>State of Nevada Constitutional Initiative</h2><br /><h2>Division Free Voting</h2>
                <Book pdf={Initiative} downloadName="book" />
              </header>
            </div>
          </div>
        </body>
      </html>
    </div>
  );
}
