import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0072 = () => {
    const [image] = useState(logo);

    return (
        <>
            <Container>
                <Row><Col>
                    <img className="image" src={image} alt="" />
                </Col>
                </Row>
                <Row><Col className="date">23.05.23</Col></Row>
                <p />
                <Row ><Col xs="12" sm="12" md="12" className="title">
                    The Weekly Heart Beat for Uniting America
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><title>Uniting America Matters</title>
                    <h1>Ranked Choice Voting = Divided Society</h1><br />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Ranked choice voting equals majority rule.</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Supporters of ranked choice voting to include Fair Vote, Political Innovation, and the League of Women Voters all state that ranked choice voting ensures majority rule.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Majority rule equals minority ignored.</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Majority rule means the majority, over 50% of the population, gets to rule. . . the minority.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Equals political division.</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Majority rule is the major problem with ranked-choice voting as it divides us into a ruling majority and a ruled minority. And its advocates don't see that as a problem.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Divided Australia.</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Next ranked-choice voting was implemented in Australia in 1918 to lock out minority parties and independents.  The conservative parties did this after losing the Swan by-election to labor.  The vote was divided between
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><li>the conservative Country Party (31%),</li> 

                <li>the conservative Nationalists (30%), </li>

                <li>an independent (5%)</li>

                <li>and the liberal Labor Party (34%)</li></Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">So successful was this effort that the government of Australia credited ranked choice for . . .</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">“the major parties <i>winning</i> 99.4 per cent of all House of Representatives contests held in the 23 Commonwealth elections since 1949.  No seat <i>as of 2007—when this report was written</i> has been won by a minor party candidate, despite three reasonably strong minor parties the Democratic Labor Party, the Australian Democrats and the Australian Greens contesting elections.” </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">The report is titled, <a href="https://parlinfo.aph.gov.au/parlInfo/search/summary/summary.w3p;adv=yes;orderBy=customrank;page=0;query=Content%3A%22Australian%20Electoral%20Systems%22%20Date%3A01%2F05%2F2007%20%3E%3E%2028%2F09%2F2007;resCount=Default">Australian Electoral Systems</a>, 21 Aug 2007 and is published by the Parliament of Australia Department of Parliamentary Services.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Divided America.</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Nonpartisan ranked choice voting elections held in California, Minnesota, Maine, and Alaska have shown how they reduce elections to only two candidates.  Of 110 elections where ranked choice voting eliminated at least one candidate, 95% of the time it eliminated all of them except two.  The winner received, on average, 55% of the vote.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Ranked choice voting works to eliminate minor party and independents leaving only the two major party candidates. Ranked-choice voting doesn't force you to choose the less of two evils, but if you don't you're ballot is eliminated 88% of the time.  And that gun is still smoking.</Col></Row><p />
                </Container>
        </>
    )
}

export default Blog0072;
