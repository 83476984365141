import { createTheme } from "@mui/material/styles";
const customTheme = createTheme({
  palette: {
    common: {
      white: "#ffffff",
      white_one: "#FFF7EF",
      black: "#000000",
      black_one: "#0B0D17",
      black_two: "#191919",
      green: "#5A8A8E",
      cream: "#FEF7DB",
      orange: "#F18F3C",
      orange_one: "#F6A916",
      orange_two: "#F7941D",
      orange_three: "#ac760f",
      orange_four: "#FF5700",
      orange_five: "#FF8F56",
      orange_six: "#EB5757",
      orange_six_rgb: "255 169 5",
      red: "#C7543D",
      pink: "#FFD6C4",
      rose: "#CE786B",
      darkGray: "#18191F",
      darkGray_one: "#3c3d45",
      gary: "#D9DBE1",
      gary_one: "#F4F5F7",
      gary_two: "#6B7280",
      blue: "#0F415E",
    },
  },

  typography: {
    fontFamily: "inter",
    h1: {
      fontSize: 72,
      lineHeight: "98px",
      fontWeight: 800,

      "@media (max-width:900px)": {
        fontSize: 50,
        lineHeight: "60px",
      },

      "@media (max-width:600px)": {
        fontSize: 40,
        lineHeight: "50px",
      },
    },
    h2: {
      fontSize: 40,
      lineHeight: "64px",
      fontWeight: 800,

      "@media (max-width:900px)": {
        fontSize: 30,
        lineHeight: "40px",
      },

      "@media (max-width:600px)": {
        fontSize: 26,
        lineHeight: "40px",
      },
    },
    h3: {
      fontSize: 30,
      lineHeight: "54px",
      fontWeight: 800,
    },
    h4: {
      fontSize: 20,
      lineHeight: "54px",
      fontWeight: 800,
    },
    h6: {
      fontSize: 24,
      lineHeight: "34px",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "32px",
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "32px",
    },
    body1: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 500,
    },
    body2: {
      fontSize: 14,
      lineHeight: "24px",
      fontWeight: 400,
    },
    button: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 500,
    },

    overline: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: "16px",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "20px",
          padding: "10px 20px",

          "@media (max-width:600px)": {
            fontSize: 12,
            padding: "8px 16px",
          },

          "@media (max-width:500px)": {
            fontSize: 11,
            padding: "8px 12px",
          },
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: "inherit",
          },
        },
      },
    },
  },
});

export default customTheme;
