import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0069 = () => {
    const [image] = useState(logo);

    return (
        <>
            <Container>
                <Row><Col>
                    <img className="image" src={image} alt="" />
                </Col>
                </Row>
                <Row><Col className="date">23.04.18</Col></Row>
                <p />
                <Row ><Col xs="12" sm="12" md="12" className="title">
                    The Weekly Heart Beat for Uniting America
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><title>Is MaxVoting Too Good to be True?</title>
                    <h1>MaxVoting methods and what they deliver</h1><br />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>The good in MaxVoting</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">MaxVoting methods, like approval voting, STAR voting, score voting, etc. do what they do because of what they press the candidate to do.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">MaxVoting methods end political division, ensure every voter is heard, and do not require anyone to change their opinion or find compromise.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">To people used to our normal plurality voting or ranked-choice voting methods this can sound too good to be true, but it's true.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>The secret of MaxVoting</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">The secret is that all voters can freely rate all candidates. When that happens, every candidate can increase their chance of winning by being closest to the majority opinion of the voters.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>The magic of the majority opinion</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">But what is the majority opinion of the voters? It ain't like there is a road sign pointing to it.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">The only way that a candidate can discover what the majority opinion is, is by listening to all of their voters. And as the majority opinion changes, slightly, any time a single voter changes their mind, candidates and representatives have to keep listening.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">And the majority opinion comes from every voters authentic opinion. We aren't looking for consensus or common ground—we need America to hear your true voice!</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Some things are too good to be true, but others are just too good! It ain't magic, but no magic could do better.</Col></Row><p />
            </Container>
        </>
    )
}

export default Blog0069;
