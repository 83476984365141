import { Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FacebookIcon, TwitterIcon, YoutubeIcon } from "../../../../img/svg";

export const FirstStep = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography fontSize="12" color="inherit">
        {t("firstStep")}{" "}
        <Link
          href="resources"
          color="inherit"
          sx={{ textTransform: "lowercase" }}
        >
          <u>{t("resources")}</u>
        </Link>{" "}
        {t("section")}.
      </Typography>
    </>
  );
};

export const SecondStep = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography fontSize="12" color="inherit">
        {t("secondStep")}{" "}
        <a href="https://www.maxvoting.org" target="_blank" color="inherit" rel="noreferrer">
          <u>{t("becomeSupporter")}</u>
        </a>
        .
      </Typography>
    </>
  );
};

export const ThirdStep = () => {
  return (
    <>
      <Stack direction="row" gap="30px">
        <Link href="https://www.facebook.com/MaximumVoting" target="_blank">
          <Stack
            justifyContent="center"
            alignItems="center"
            bgcolor="#000"
            borderRadius="50%"
            width="24px"
            height="24px"
            sx={{ opacity: "0.3" }}
          >
            <FacebookIcon />
          </Stack>
        </Link>

        <Link href="https://twitter.com/MaxVoting" target="_blank">
          <Stack
            justifyContent="center"
            alignItems="center"
            bgcolor="#000"
            borderRadius="50%"
            width="24px"
            height="24px"
            sx={{ opacity: "0.3" }}
          >
            <TwitterIcon />
          </Stack>
        </Link>

        <Link
          href="https://www.youtube.com/channel/UCqHP9kv-hN7E-AvU9bEq06Q"
          target="_blank"
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            bgcolor="#000"
            borderRadius="50%"
            width="24px"
            height="24px"
            sx={{ opacity: "0.3" }}
          >
            <YoutubeIcon />
          </Stack>
        </Link>
      </Stack>
    </>
  );
};

export const ForthStep = () => {
  const { t } = useTranslation();

  return (
    <>
      <ul style={{ paddingLeft: "13.5px" }}>
        <li>
          <a href="https://www.maxvoting.org" target="_blank" color="inherit" rel="noreferrer">
            <Typography
              fontSize="12"
              color="inherit"
              textTransform="capitalize"
            >
              <u>{t("becomeSupporter")}</u>
            </Typography>
          </a>
        </li>
        <li>
          <Link href="/coming-soon" color="inherit">
            <Typography fontSize="12" color="inherit">
              <u>{t("donate")}</u>
            </Typography>
          </Link>
        </li>
        <li>
          <Link href="/coming-soon" color="inherit">
            <Typography fontSize="12" color="inherit">
              <u>{t("volunteer")}</u>
            </Typography>
          </Link>
        </li>
      </ul>
    </>
  );
};

export const FifthStep = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography fontSize="12" color="inherit">
        {t("thirdStepHeading")}
      </Typography>
      <ul style={{ paddingLeft: "13.5px" }}>
        <li>
          <Typography fontSize="12" color="inherit">
            {t("thirdStepPointOne")}
          </Typography>
        </li>
        <li>
          <Typography fontSize="12" color="inherit">
            {t("thirdStepPointTwo")}
          </Typography>
        </li>
        <li>
          <Typography fontSize="12" color="inherit">
            {t("thirdStepPointThree")}
          </Typography>
        </li>
        <li>
          <Typography fontSize="12" color="inherit">
            {t("thirdStepPointFour")}
          </Typography>
        </li>
      </ul>
    </>
  );
};
