import React from "react";
import Book from "./book";
// import SVBook from "./book.pdf";

import "./App.css";
// console.log('SVBook>>>',SVBook)
export default function BookBase() {
  return (
    <div>
      <html lang="en">
        <head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          ></meta>
        </head>
        <body>
          <div className="container-fluid" align="center">
            <div className="Example">
              <header align="center">
                <h2>Draft of Max Voting Book 22.02.05 (it takes a while to load)</h2>
                <Book  downloadName="book" />
              </header>
            </div>
          </div>
        </body>
      </html>
    </div>
  );
}
