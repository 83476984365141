import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

const BE_URL = process.env.REACT_APP_BE_URL;

export const registerSupporter = async (requestBody) => {
  const newId = uuidv4();

  const newBody = {
    ...requestBody,

    newId,
  };

  const url = `${BE_URL}/api/supporters/supporters`;
  try {
    const response = await axios.post(url, newBody);

    const supporterPosition = await supporterNumber({
      state: response.data.state,
      county: response.data.county,
      precinct: response.data.precinct,
      nation: response.data.nation,
      // levelValues: response.data.levelValues,
    });
    const certResponse = { ...supporterPosition, ...newBody };
    return { data: certResponse };
  } catch (e) {
    return { error: (e.response && e.response.data) || e.message };
  }
};

// need to do a series of calls, one for each level and get supporter totals.
export const supporterNumber = async ({ nation, state, county, precinct }) => {
  const urlNation = `${BE_URL}/api/total/supporters/${nation}`;
  const urlState = `${BE_URL}/api/total/supporters/${nation}/${state}`;
  const urlCounty = `${BE_URL}/api/total/supporters/${nation}/${state}/${county}`;
  const urlPrecinct = `${BE_URL}/api/total/supporters/${nation}/${state}/${county}/${precinct}`;
  try {
    const responseNation = await axios.get(urlNation);
    const responseState = await axios.get(urlState);
    const responseCounty = await axios.get(urlCounty);
    const responsePrecinct = await axios.get(urlPrecinct);
    const nationNum = Math.floor(responseNation.data.headSupportTotal);
    const stateNum = Math.floor(responseState.data.headSupportTotal);
    const countyNum = Math.floor(responseCounty.data.headSupportTotal);
    const precinctNum = Math.floor(responsePrecinct.data.headSupportTotal);
    const movement = "MaxVoting";
    const movementHash = "#MaxVoting";
    const mission =
      "spread an understanding of the power of MaxVoting, in all of its forms, to solve our current crisis of toxic political division, and to coordinate the submission of MaxVoting legislation in every state.";

    const levelValues = [
      [
        nationNum / responseNation.data.headVoteTotal,
        (nationNum - 1.0) / responseNation.data.headVoteTotal,
      ],
      [
        stateNum / responseState.data.headVoteTotal,
        (stateNum - 1.0) / responseState.data.headVoteTotal,
      ],
      [
        countyNum / responseCounty.data.headVoteTotal,
        (countyNum - 1.0) / responseCounty.data.headVoteTotal,
      ],
      [
        precinctNum / responsePrecinct.data.headVoteTotal,
        (precinctNum - 1.0) / responsePrecinct.data.headVoteTotal,
      ],
    ];

    return {
      nationNum,
      stateNum,
      countyNum,
      precinctNum,
      movement,
      mission,
      levelValues,
      responseNation,
      responseState,
      responseCounty,
      responsePrecinct,
      movementHash,
    };
  } catch (e) {
    return { error: (e.response && e.response.data) || e.message };
  }
};

export const sendVerificationEmail = async (email) => {
  const url = `${BE_URL}/api/supporters/supporters/verify-email`;
  try {
    const response = await axios.post(url, { email });
    return { data: response.data };
  } catch (e) {
    return { error: (e.response && e.response.data) || e.message };
  }
};

export const forgottenPassword = async ({ email, password }) => {
  const url = `${BE_URL}/api/supporters/supporters/forgotten-password`;
  try {
    const response = await axios.post(url, { email, password });
    return { data: response.data };
  } catch (e) {
    return { error: (e.response && e.response.data) || e.message };
  }
};

export const verifyCode = async (email, code) => {
  const url = `${BE_URL}/api/supporters/supporters/verify-code`;
  try {
    const response = await axios.post(url, {
      email,
      code,
      TEST: process.env.REACT_APP_ALLOW_TESTING === "true",
    });
    return { data: response.data };
  } catch (e) {
    return { error: (e.response && e.response.data) || e.message };
  }
};
