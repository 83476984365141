import React, { Component } from "react";
import { Row, Col, Button, Container } from "reactstrap";
import "./components/StaticTitleBar/StaticTitleBar.css";
import AffliatedButton from "./components/StaticTitleBar/affiliatedButton";
import SupporterButton from "./components/dynamicHeader/supporterButton";
// import * as constants from "./components/StaticTitleBar/const";
import Blog0072 from './blogs/blog0072';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

export default class Resources extends Component {


  buttonChoice() {
    if (!this.props.session) return <SupporterButton />;
    else return <AffliatedButton />;
  }


  render() {
    return (
      <>
        {/* taken from static title bar
        This is a two-by-two gird on left with video on right*/}
        <Container style={{ color: "#5A8A8E " }}>
          <Row >
            <Col
              xs="12"
              sm="12"
              md="12"
              className="static-column highlighted-title tag-line"
              style={{ fontSize: '250%', justifyContent: 'center', paddingBottom: "60px", paddingTop: "30px", color: "#C7543D" }}
            >
              Resources for the Swarm.  (Gotta feed the swarm!)
            </Col>
          </Row>

          <Row style={{ justifyContent: 'center' }} >
            <Col xs="12"
              sm="12"
              md="6"
              lg="6"
              style={{ justifyContent: 'center' }} className="static-column highlighted-title tag-line-second">

              <table height="320px"
                marginLeft="auto"
                marginRight="auto"
                width="100%"
                tableLayout="fixed"
                paddingLeft="20px"
                pandingTop="50px"

              >
                <th colspan="2" style={{ paddingBottom: "15px", justifyContent: 'center', color: '#5A8A8E' }}><br/>Learn more from our substack.<br /></th>

                <tr height="300px">
                  <td
                    xs="12"
                    sm="12"
                    md="4"
                    className="static-column highlighted-title tag-line-second"
                    width="33%"
                  ><span style={{ color: "#C7543D" }}><b></b></span>
                    <iframe src="https://commonsenseforunitingamerica.substack.com/embed" title="substack" width="100%" height="300" style={{ border: "1px solid #EEE", background: "white" }} frameborder="0" scrolling="no"></iframe>
                  </td>


                  {/* <td
                    width="33%"
                    style={{ justifyContent: 'center', alignContent: 'center' }}
                    className="static-column highlighted-title tag-line-second">

                    <a href="mailto:commonsenseforunitingamerica@gmail.com?subject = Feedback&body = Message" style={{ color: "red" }}>
                      <br /><span style={{ color: "#C7543D" }}><b></b></span>
                      <iframe src="https://www.volunteermatch.org/search/org1231163.jsp" title="Volunteer Match" width="100%" height="300" style={{ border: "1px solid #EEE", background: "white" }} frameborder="0" scrolling="no"></iframe>
                    </a>
                  </td> */}
                </tr>

              </table>
            </Col>

            <Col className="video-zone" xs="12" sm="12" md="6" lg="6" >
              <div class="contained">

                <iframe
                  title="video play list"
                  width="100%"
                  height="100%"
                  minHeight="280px"
                  src="https://www.youtube.com/embed?listType=playlist&list=PLQod5if9cV9hiWml-4ZyQQNWvys8vlYHM&autoplay=1&loop=1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  class="video"
                ></iframe></div></Col>

          </Row><br /><br /><br />

          <Row style={{
            display: "flex",
            borderBottom: "1px solid grey",
          }}></Row>

          <Row >

            <Col xs="12" sm="12" md="12" className="static-column" style={{ fontSize: '250%', justifyContent: 'center', paddingTop: "30px", paddingBottom: "30px" }}>
              <span style={{ color: "#5A8A8E", fontFamily: "Gill Sans MT" }}><b><i>Great References</i></b></span>
            </Col>
          </Row>

          <Row style={{ justifyContent: 'center' }}>

            <Col xs="12"
              sm="12"
              md="4"
              className="static-column highlighted-title tag-line-second"
            >
              <a href="/FAQs" target="_blank" rel="noopener noreferrer"
              ><br />
                <span style={{ color: "#5A8A8E" }}>Frequently Asked Questions</span>
              </a>
            </Col>

            <Col xs="12"
              sm="12"
              md="4"
              className="static-column highlighted-title tag-line-second"
            >
              <a href="/Initiative" target="_blank" rel="noopener noreferrer"
              >
                <span style={{ color: "#5A8A8E" }}> Division Free Voting<br />
                  Nevada State<br />Constitutional Initiative</span>
              </a>
            </Col>

            <Col xs="12"
              sm="12"
              md="4"
              className="static-column highlighted-title tag-line-second"
            >
              <a href="/press" target="_blank" rel="noopener noreferrer"
              ><br /><span style={{ color: "#5A8A8E" }}> Press Room</span>
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs="12"
              sm="12"
              md="4"
              className="static-column highlighted-title tag-line-second"
            >
              <a href="/book" target="_blank" rel="noopener noreferrer"><br /><br /><br />
                <span style={{ color: "#5A8A8E" }}> Read our draft book</span>
              </a>
              {/* {constants.EMPOWERING} */}</Col>

            <Col
              xs="12"
              sm="12"
              md="4"
              lg="4"
              className="static-column highlighted-title tag-line-second"
            >
              <a href="https://getfullyfunded.com/getstarted" target="_blank" rel="noopener noreferrer"><br /><br /><span style={{ color: "#5A8A8E" }}>
                Read the Funding Manual,<br /><u>Fund Your Dream</u>,<br />by Sandy Rees</span>
              </a>
            </Col>{" "}

            <Col
              xs="12"
              sm="12"
              md="4"
              className="static-column highlighted-title tag-line-second"
            >
              <a
                href="https:rangevoting.org"
                target="_blank"
                rel="noopener noreferrer"
              ><br /><br /><span style={{ color: "#5A8A8E" }}>
                  More about MaxVoting methods<br />(at rangevoting.org)<br />with Dr. Warren Smith.</span>
              </a>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              sm="12"
              md="4"
              className="static-column highlighted-title tag-line-second"
            >
              <a href="https://falkvinge.net/files/2013/04/Swarmwise-2013-by-Rick-Falkvinge-v1.1-2013Sep01.pdf" target="_blank" rel="noopener noreferrer"><br /><br /><span style={{ color: "#5A8A8E" }}>
                Read the Swarm Manual,<br /><u>Swarm Wise</u>,<br />by Rick Falvinge</span>
              </a>
              {/* {constants.EMPOWERING} */}
            </Col>{" "}

            <Col
              xs="12"
              sm="12"
              md="4"
              className="static-column highlighted-title tag-line-second"
            >
              <a
                href="https://vote-nevada.news/Ballot-Question-Signature-Gathering-Rules"
                target="_blank"
                rel="noopener noreferrer"
              ><br /><br /><span style={{ color: "#5A8A8E" }}>
                  Vote Nevada presents<br /><i>how to collect signatures<br />for the ballot initiative.</i></span>
              </a>
            </Col>


            <Col
              xs="12"
              sm="12"
              md="4"
              className="static-column highlighted-title tag-line-second"
            >
              <a
                href="https://www.amazon.com/Flip-Script-Oren-Klaff-audiobook/dp/B07VH18F6N/ref=sr_1_1?crid=YQDCXXIR2B5R&keywords=flip+the+script&qid=1644439178&s=audible&sprefix=flip+the+script%2Caudible%2C306&sr=1-1"
                target="_blank"
                rel="noopener noreferrer"
              ><br /><br /><span style={{ color: "#5A8A8E" }}>
                  How to pitch MaxVoting<br /><u>Flip the Script</u><br />by Oren Klaff<br /></span>
              </a>
            </Col>
          </Row>
          <Row style={{ justifyContent: 'center' }}>

            <Col
              xs="12"
              sm="12"
              md="4"
              className="static-column highlighted-title tag-line-second"
            >
              <a
                href="https://www.amazon.com/Never-Split-Difference-audiobook/dp/B01COR1GM2/ref=sr_1_1?crid=1M491EWJWPMA&keywords=never+split+the+difference&qid=1644439067&sprefix=never+split+the+differe%2Caps%2C518&sr=8-1"
                target="_blank"
                rel="noopener noreferrer"
              ><br /><br /><span style={{ color: "#5A8A8E" }}>
                  Build your persuasion skills<br /><u>Never Split the Difference</u>,<br />by Cris Voss<br /></span>
              </a>
            </Col>

            <Col
              xs="12"
              sm="12"
              md="4"
              className="static-column highlighted-title tag-line-second"
            >
              <a
                href="https://www.amazon.com/Good-Room-Yourself-Ideas-Audience/dp/0385520433/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1644439358&sr=1-2"
                target="_blank"
                rel="noopener noreferrer"
              ><br /><br /><span style={{ color: "#5A8A8E" }}>
                  How to ptich MaxVoting<br /><u>Good in a Room</u>,<br />by Stephanie Palmer</span>
              </a>
            </Col>

            <Col
              xs="12"
              sm="12"
              md="4"
              className="static-column highlighted-title tag-line-second" style={{ alignContent: 'center' }}
            >
              <a
                href="https://twitter.com/MaxVoting"
                target="_blank"
                rel="noopener noreferrer"
              ><br /><br /><br /><span style={{ color: "#5A8A8E" }}>
                  "Watch-us-grow" tweets</span>
              </a>

            </Col>
          </Row>
          <Row style={{ justifyContent: 'center' }}>

            <Col
              xs="12"
              sm="12"
              md="6"
              className="static-column highlighted-title tag-line-second"
            >
              <a
                href="https://www.amazon.co.uk/s?k=who+not+how&adgrpid=107553480411&gclid=Cj0KCQjw6pOTBhCTARIsAHF23fJ7OMmW5lHPj9dd6ACmKvnvkPVKYq89_lxdGQrHfmzuiM6B7hDOVO8aAnnKEALw_wcB&hvadid=438465386847&hvdev=c&hvlocphy=9046207&hvnetw=g&hvqmt=e&hvrand=17763544459318160052&hvtargid=kwd-913779671947&hydadcr=18516_1816627&tag=googhydr-21&ref=pd_sl_3gfqqu4pv4_e"
                target="_blank"
                rel="noopener noreferrer"
              ><br /><br /><span style={{ color: "#5A8A8E" }}>
                  Do more, better, faster<br /><u>Who not How</u>,<br />by Dr. Benjamin Hardy<br /></span>
              </a>
            </Col>

            <Col
              xs="12"
              sm="12"
              md="6"
              className="static-column highlighted-title tag-line-second"
            >
              <a
                href="https://resources.strategiccoach.com/quarterly-books/your-life-as-a-strategy-circle"
                target="_blank"
                rel="noopener noreferrer"
              ><br /><br /><span style={{ color: "#5A8A8E" }}>
                  Make obstacles assets<br /><u>Your Life as a Strategy Circle</u>,<br />by Dan Sullivan</span>
              </a>
            </Col>




          </Row>


          <Row><br /><br /><br /></Row>

          <Row style={{
            display: "flex",
            borderBottom: "1px solid grey",
          }}><br /></Row>
          <Row style={{ marginTop: "60px", padding: "20px" }}>
            <Col xs="12" sm="12" md="4" style={{ textAlign: 'center' }}>
              <div>
                <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName="MaximumVoting"
                  options={{ height: 700 }}
                />

              </div><br /><br /><br />
              <div>
                <TwitterTimelineEmbed
                  sourceType="https://twitter.com/MaxVoting"
                  screenName="VotingScore"
                  options={{ height: 700 }}
                />


              </div>
            </Col>
            <Col xs="12" sm="12" md="8" >


              <Row>
                <Col xs="12" sm="12" md="2" lg="2" ></Col>
                <Col xs="12" sm="12" md="4" lg="4" style={{ aligntext: "center" }}>Current Heart Beat</Col>
                <Col xs="12" sm="12" md="4" lg="4" style={{ display: "flex", aligntext: "center" }}>
                  <Button variant="outline-secondary" style={{ marginLeft: 'auto' }} href="/heartBeat" >Past Heart Beats</Button>
                </Col> <Col xs="12" sm="12" md="2" lg="2" ></Col>
              </Row>
              <Row>

                <Col xs="12" sm="12" md="12">
                  <Blog0072 /><br />
                </Col>
              </Row>

            </Col>
          </Row>


        </Container>
      </>
    );
  }
}
