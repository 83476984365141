import _ from "lodash";

export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
}

export const openInNewTab = (url) => {
  window.open(url, "_blank");
};

export const getLanguage = (language) => {
  switch (language) {
    case "en":
      return "en";
    case "es":
      return "es";
    default:
      return "en";
  }
};
