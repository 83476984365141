import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0071 = () => {
    const [image] = useState(logo);

    return (
        <>
            <Container>
                <Row><Col>
                    <img className="image" src={image} alt="" />
                </Col>
                </Row>
                <Row><Col className="date">23.05.02</Col></Row>
                <p />
                <Row ><Col xs="12" sm="12" md="12" className="title">
                    The Weekly Heart Beat for Uniting America
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><title>Uniting America Matters</title>
                    <h1>Why Uniting America with MaxVoting is key</h1><br />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Why MaxVoting is our top priority</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">There is arguably nothing more critical than instituting MaxVoting throughout the United States.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">As a U.S. Navy Commander, I watched our enemies tear us apart by building troll cells.  One hacker would build sites on each side of a controversial issue.  They would then attack themself, throwing vicious barbs back and forth. Americans ate it up. They jumped on the bandwagon of either side in droves, happily hating each other.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">As a Foreign Area Officer, I had to explain why the U.S. could not plan even a year or two ahead.   There was an election coming, after all, and a new administration would likely make major changes.  Maybe that program or exercise would happen, maybe not.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">As a family member, I watch fellow family members refuse to talk to each due to political differences.  The uncomfortableness of holidays, the constant classifying of those with a different political perspective as evil or dangerous. In the 1860s we killed 1% of our population using muzzle loaded muskets.  We don't use muskets today.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Our political division is our greatest threat</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">As a military and defense professional, I believe our nation's single greatest existential threat is our political division.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Not just because our enemies can use it against us, </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">not just because it keeps us from planning for the future,</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">not just because it drives us toward civil war,</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">but because it robs us of our ability to take on the challenges our nation faces today.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">We fight over where we should go, and who should drive, but our car has no engine.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>MaxVoting can save us</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">MaxVoting allows the government to function so we can take on our nation's challenges. It makes us resilient against our enemies and brings families together.</Col></Row><p />
            </Container>
        </>
    )
}

export default Blog0071;
