import React, { Suspense, useContext, useLayoutEffect } from "react";
import { Route, Switch } from "react-router-dom";
import BookBase from "./bookBase";
import FAQBase from "./FAQBase";
import InitiativeBase from "./InitiativeBase";
import Central from "./central";
import HeartBeat from "./heartBeat";
import Ads from "./ads";
import Resources from "./resources.jsx";
import Press from "./press.jsx";
import R220301 from "./pressReleases/r220301base";
import "./helpers/i18n";

import "bootstrap";
import customTheme from "./theme";
import ApplicationLayout from "./layout/applicationLayout";
// import Home from "./pages/app/home";
import ComingSoon from "./components/common/comingSoon";
import { ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "./context/language";
import Loader from "./components/common/loader";
// import TOU from "./pages/LegalInformation/TOU";
// import PP from "./pages/LegalInformation/PP";
const Home = React.lazy(() => import("./pages/app/home"));

const App = () => {
  const { i18n } = useTranslation();
  const { language } = useContext(LanguageContext);

  useLayoutEffect(() => {
    if (localStorage.getItem("language") !== "") {
      i18n.changeLanguage(localStorage.getItem("language"));
    }
  }, [i18n, language]);

  return (
    <ThemeProvider theme={customTheme}>
      <div className="navi" style={{ overflowX: "hidden" }}>
      <Switch>
        {/* <Route path="/TermsOfUsage" component={TOU} />
        <Route path="/PrivacyPolicy" component={PP} /> */}
        <Route path="/book" component={BookBase} />
        <Route path="/FAQs" component={FAQBase} />
        <Route path="/Initiative" component={InitiativeBase} />
        <Route path="/press220301" component={R220301} />
        <Route path="/press" component={Press} />
        <Route path="/resources" component={Resources} />
        <Route path="/heartBeat" component={HeartBeat} />
        <Route path="/test" component={HeartBeat} />
        <Route path="/ads.txt" component={Ads} />
        <Route path="/coming-soon" component={ComingSoon} />
        <Route path="/old-ui" component={Central} />

        <Route path="/">
          <Suspense fallback={<Loader />}>
            <ApplicationLayout>
              <Route path="/" component={Home} />
            </ApplicationLayout>
          </Suspense>
        </Route>
      </Switch>
      </div>
    </ThemeProvider>
  );
};

export default App;
