import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0070 = () => {
    const [image] = useState(logo);

    return (
        <>
            <Container>
                <Row><Col>
                    <img className="image" src={image} alt="" />
                </Col>
                </Row>
                <Row><Col className="date">23.04.25</Col></Row>
                <p />
                <Row ><Col xs="12" sm="12" md="12" className="title">
                    The Weekly Heart Beat for Uniting America
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><title>The CSUA Organization</title>
                    <h1>Common Sense for Uniting America</h1><br />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Where are we going?</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Our ultimate goal is gain enough supporters to allow them to start political initiatives to adopt MaxVoting methods.  In this case, enough supporters for that to happen in Hocking County.Our ultimate goal is gain enough supporters to allow them to start political initiatives to adopt MaxVoting methods.  In this case, enough supporters for that to happen in Hocking County.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>We need you</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">You can start to make that happen by becoming one of our supporters.  </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">The simplest way to bring in more supporters is to learn more about MaxVoting and invite others to join.  We are here to help you learn the answers to their questions, which is why we have this resource site.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Hocking County, Ohio</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">We calculate, that if 1500 new supporters join from Hocking County, that should be enough to start a movement to end political division in Hocking.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">This should cost Common Sense for Uniting America about $65,961.50 and you can monitor our progress via our this site using the contribute button.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Maybe advocating for MaxVoting is as far as you can go; that's fine and not a problem.  But for those who truly want to see a job well done, we also have volunteer opportunities. </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Volunteer with us</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">You can do social media outreach, contributor support, volunteer support, research, program management.  You'll get both training and opportunities to super-power your ability to end our political nightmare fast.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>When you're ready</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">So if you are ready to take that next step, either by supporting, advocating, or volunteering, now is the time!</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Time is running out, but . . .</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">there is no reason why we should continue to suffer our political division,</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">there is no reason why your voice should be silenced after your "side" fails to win this next election,</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">there is no reason why our government should be unable to work harmoniously and your challenges,</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">there is no reason why Gen Z can't be the last U.S. generation to suffer the pain of political division.</Col></Row><p />
                </Container>
        </>
    )
}

export default Blog0070;
