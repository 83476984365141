import { Box } from "@mui/material";
import React from "react";
import Footer from "../../components/common/footer";
import Navbar from "../../components/common/navbar";

const ApplicationLayout = ({ children }) => {
  return (
    <Box>
      <Navbar />
      {children}
      <Footer />
    </Box>
  );
};

export default ApplicationLayout;
