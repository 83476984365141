import { styled, InputBase, alpha } from "@mui/material";

export const TextInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: "#202020",
  },
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    color: "#6B7280",
    border: "1px solid #EEF6FF",
    padding: "12px 16px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      boxShadow: `${alpha(
        theme.palette.common.orange_four,
        0.55
      )} 0 0 0 0.2rem`,
      outline: theme.palette.common.orange_four,
    },
  },
}));

export const InputLabelStyle = {
  color: "#202020",
  fontSize: 16,
  lineHeight: "24px",
  fontWeight: 500,

  "&.Mui-focused": {
    color: "#5a8a8e",
  },
};
