import React from "react";
import Book from "./FAQ";
import SVBook from "./FAQ.pdf";

import "./App.css";

export default function FAQBase() {
  return (
    <div>
      <html lang="en">
        <head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          ></meta>
        </head>
        <body>
          <div className="container-fluid" align="center">
            <div className="Example">
              <header align="center">
                <h2>Frequently Asked Questions as of 21.12.28</h2>
                <Book pdf={SVBook} downloadName="book" />
              </header>
            </div>
          </div>
        </body>
      </html>
    </div>
  );
}
