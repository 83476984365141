import React from "react";
import Book from "../book";
import R220301 from "./220301.pdf";

import "../App.css";

export default function R220301Base() {
  return (
    <div>
      <html lang="en">
        <head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          ></meta>
        </head>
        <body>
          <div className="container-fluid" align="center">
            <div className="Example">
              <header align="center">
                <b>01 March 2022</b>: <br /> Division Free Voting State Constitutional Initiative
                <Book pdf={R220301} downloadName="220123_Ask_A_Different_Question" />
              </header>
            </div>
          </div>
        </body>
      </html>
    </div>
  );
}
