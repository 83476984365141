import React, { Component } from 'react';
import { Row, Col } from "reactstrap";

class EndZone extends Component {

  render() {
    return <>
      <Row className="end-zone-row">
        <Col xs="12" sm="4" md="4" className="end-left-zone end-zone-block">
          {" "}
          contact and legal information{" "}
        </Col>
        <Col xs="12" sm="4" md="4" className="end-zone-block end-zone-extra">
          <p>
            contacts:
            <br />
            <br />
            Common Sense for Uniting America Movement:
            <br />
            mailbox@commonsenseforunitingamerica.org
            <br />
            <br />
            Swarm Age management:
            <br />
            - admin@swarmage.org
            <br />
            <br />

          </p>
        </Col>
        <Col xs="12" sm="4" md="4" className="end-zone-block end-zone-extra">
          <p>
            legal information: <br />
            <br />
            <br />
            <a
              href="https://swarmage-org-frontend.herokuapp.com/TermsOfUsage"
              target="_blank"
              rel="noopener noreferrer"
            >
              Swarm Age Terms of Service
            </a>
            <br />
            <br />
            <a
              href="https://swarmage-bucket.s3.us-east-2.amazonaws.com/CSFUA+Privacy+Policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Swarm Age Privacy Policy
            </a>
            <br />
            <br />
            <a
              href="https://policies.google.com/technologies/partner-sites"
              target="_blank"
              rel="noopener noreferrer"
            >
              We use Google AdSense. Link to Google AdSense Privacy Policy is
              here!
            </a>
          </p>
        </Col>
      </Row>
    </>

  }
}

export default EndZone;